import React from "react"
import * as Icon from "react-feather"
import { graphql } from "gatsby"

import CaseDetail from "../../components/Shared/casedetails"

const seoTitle = "Exposure Risk Analysis"
const pageTitle = "Exposure Risk Analysis"
const subTitle = "Data Science"
const para =
  "The Market Data, Trade Data and Reference Data used to build these feeds and valuations are collected from various golden sources.The market data keeps changing during the course of the day – this makes capturing a snapshot of the market data in the current system difficult - this in turn impacts the ability to generate risk feeds and valuations for decision making in a timely manner. Assets may have complex relationships and nested structures, necessitating graph based modeling."
const blockquote = `"A RISK VALUATION AND FEED GENERATION SYSTEM"`

const cardItems = [
  {
    id: 1,
    heading: `Solution:`,
    listItems: (
      <>
        <li>
          <Icon.Square />
          Use Databricks / Snowflake to ingest, transform and model data
        </li>
        <li>
          <Icon.Square />
          Since upstream systems cannot preload market data based on the
          previous days feeds, snapshots of these market data are used for Risk
          Calculation to create an accurate Risk Valuation and Risk feed.
        </li>
        <li>
          <Icon.Square />
          This is done based as a solution leveraging Microservices.
        </li>
      </>
    ),
  },
  {
    id: 2,
    heading: `Technical Solution`,
    listItems: (
      <>
        <li>
          <Icon.Square />
          During peak time, spark based data framework can scale to ensure
          efficient processing of data.
        </li>
        <li>
          <Icon.Square />
          The system built is Cloud ready and deployed on a public cloud
        </li>
        <li>
          <Icon.Square />
          Built an Analytic Engine for breaking a large book of trade into
          separate tasks - process these tasks gathering information from
          various sources and provide a timely Risk Calculation{" "}
        </li>
      </>
    ),
  },
  {
    id: 3,
    heading: `Technical Implementation`,
    listItems: (
      <>
        <li>
          <Icon.Square />
          DevOps and CI/CD principles for zero down time for deployments.
        </li>
        <li>
          <Icon.Square />
          The Atyeti team designed a configurable system from batch to mini
          batch and stream processing architectures for the subscription and
          registration of data.
        </li>
        <li>
          <Icon.Square />
          The Risk calculation process was refactored out of the application and
          built as an independently deployable and scalable unit
        </li>
        <li>
          <Icon.Square />
          Build the application Cloud ready and Cloud Agnostic{" "}
        </li>
        <li>
          <Icon.Square />
          Uniqueness: De-duplication and redundancy elimination
        </li>
      </>
    ),
  },
  {
    id: 4,
    heading: `Data Services`,
    listItems: (
      <>
        <li>
          <Icon.Square />
          Built the application using Spark for Scalability, Resilience and
          Fault tolerance
        </li>
        <li>
          <Icon.Square />
          Task parallelization on Spark using Ray.io
        </li>
        <li>
          <Icon.Square />
          Deployed CI/CD using Jenkins
        </li>
        <li>
          <Icon.Square />
          Deployed Industry standards and best practices in developing Cloud
          ready solution{" "}
        </li>
      </>
    ),
  },
  {
    id: 4,
    heading: `Result`,
    listItems: (
      <>
        <li>
          <Icon.Square />
          The Application can now generate Risk feeds in near Real-Time
        </li>
      </>
    ),
  },
]
const BlogDetails = ({ data }) => (
  <CaseDetail
    seoTitle={seoTitle}
    pageTitle={pageTitle}
    cardItems={cardItems}
    subTitle={subTitle}
    para={para}
    blockquote={blockquote}
    images={data}
  />
)
export const query = graphql`
  query ExposureRiskAnalysisSolutionQuery {
    casedetailsJson(slug: { eq: "exposure-risk-analysis-solution" }) {
      images {
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      slug
    }
  }
`
export default BlogDetails
